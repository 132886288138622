.Welcome {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    color: white;
    background-color: #565689;
}

.Welcome .profile {
    width: 256px;
    height: 256px;
    border-radius: 10px;
}

.Welcome .slogan {
    font-size: 50px;
}