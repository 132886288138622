.App {
    padding: 0;
    display:flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 100vh;
}

.App .content {
    display:flex;
    flex-direction: column;
    width: 75vw;
    min-width: 750px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #9393D7;
    min-height:100%;
    height:100%;
    flex-grow:1;
    flex-shrink: 0;
}

body {
    padding:0;
    margin:0;
}